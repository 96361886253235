.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


h4,h3 {
  font-size: 50px;
  background: -webkit-linear-gradient(45deg, #f8a774 30%, #f6567e 90%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.circle1{
 background-color:#f8a774;
  opacity: 0.5;
  height:150px;
  width:150px;
  --webkit-border-radius:100px;
  border-radius:100px;
  position: fixed;
  left: -60px;
  top: -60px;
}

.circle2{
 background:-webkit-linear-gradient(45deg, #f8a774 30%, #f6567e 90%);
  opacity: 0.2;
  height:280px;
  width:280px;
  --webkit-border-radius:200px;
  border-radius:200px;
  position: fixed;
  right: -100px;
  z-index: 0;
  top: 100px;
}
